@font-face {
  font-family: MainFont;
  src: url("../../Fonts/Rubik-VariableFont_wght.ttf");
}

body {
  font-family: MainFont;
}

.Head {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Head div {
  width: 450px;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Head div p {
  color: #00b2ff;
  font-size: 25px;
  direction: rtl;
  font-weight: 400;
  width: 100%;
}

.Head img {
  height: 100%;
  width: auto;
}

.Head h1 {
  color: #00b2ff;
  font-size: 50px;
  font-weight: 400;
  width: 100%;
  direction: rtl;
}

.TeachersList {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px 0 20px 0;
  margin-top: 50px;
  height: fit-content;
}

.TeacherCard {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 200px;
  background: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.TeacherCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.TeacherImage {
  width: 100%;
  height: auto;
  border-radius: 50%;
  margin-bottom: 10px;
  object-fit: cover;
}

.TeacherInfo h2 {
  font-size: 25px;
  font-family: MainFont;
  margin: 10px 0;
  color: #00b2ff;
  font-weight: 400;
}

.TeacherInfo p {
  font-family: MainFont;
  font-size: 25px;
}

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ModalContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  text-align: center;
}

.notes-container {
  background-color: #20b850;
  color: #fff;
  padding: 15px;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  border-radius: 5px;
  position: fixed;
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
  width: 80%;
  height: fit-content;
  z-index: 1;
  text-align: start;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  direction: rtl;
}
